/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: var(--color-bg-1);
  color: var(--color-text-1);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #3C7EFF;
  border-radius: 10px;
  border: 8px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #93BEFF;
}

.arco-card {
  min-height: 210px;
}

.arco-card-header-title {
  color: #7DC1FC !important;
  letter-spacing: 1px;
  font-weight: 300 !important;
}

body, html {
  height: 100%;
}

.bg {
  background-image: url("../public/background3_2.jpg");
  height: 100%;
  width: 100vw;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  mask-image: linear-gradient(rgba(0, 0, 0, 1.0) 80%, transparent 100%);

}

a {
  text-decoration: none; /* no underline */
}


.col {
  flex: 1;
}
.flex-grid-thirds {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  flex-flow: row wrap;
}



.page-title {
  font-size: 6vw;
  letter-spacing: 5px;
  margin-top: 100px;
}

.page-subtitle {
  font-size: 3vw !important;
}
.flex-col {
  margin: 50px;
}

@media (max-width: 800px) {
  .flex-grid-thirds {
    display: block;
  }
}

@media (max-width: 400px) {

  .bg {
    background-color: black;
    height: 100%;
    width: 100vw;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mask-image: linear-gradient(rgba(0, 0, 0, 1.0) 80%, transparent 100%);
  
  }
  .flex-grid-thirds {
    display: block;
  }


  .page-title {
    font-size: 20vw !important;
    letter-spacing: 5px;
    margin-top: 100px !important;
  }

  .page-subtitle {
    font-size: 6vw !important;
  }


}